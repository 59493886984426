var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            staticClass: "progress",
            attrs: { size: 50, color: "primary", indeterminate: "" },
          })
        : _c(
            "v-form",
            { attrs: { readonly: !_vm.canEditSettings } },
            [
              _c("v-card-title", [_vm._v("Display or Hide Form Fields")]),
              [
                _c(
                  "v-expansion-panels",
                  { staticClass: "my-2", attrs: { popout: "" } },
                  [
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase font-weight-medium grey lighten-3",
                          },
                          [_vm._v(" General ")]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "v-row",
                              { staticClass: "my-2", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v("Display Staff Only Category?"),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.staffCategory,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "staffCategory",
                                              $$v
                                            )
                                          },
                                          expression: "display.staffCategory",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase font-weight-medium grey lighten-3",
                          },
                          [_vm._v(" Leave / Return ")]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-row",
                              { staticClass: "my-2", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _vm.me.is.superAdmin
                                      ? _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "4" },
                                              },
                                              [
                                                _c("v-subheader", [
                                                  _vm._v(
                                                    "Display Recurring Identical Trips button on the trip form?"
                                                  ),
                                                ]),
                                                _c(
                                                  "v-radio-group",
                                                  {
                                                    staticClass: "ml-4",
                                                    attrs: { row: "" },
                                                    model: {
                                                      value:
                                                        _vm.display
                                                          .recurringTrips,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.display,
                                                          "recurringTrips",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "display.recurringTrips",
                                                    },
                                                  },
                                                  [
                                                    _c("v-radio", {
                                                      attrs: {
                                                        label: "Yes",
                                                        value: true,
                                                      },
                                                    }),
                                                    _c("v-radio", {
                                                      attrs: {
                                                        label: "No",
                                                        value: false,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.display.recurringTrips
                                              ? _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "4",
                                                    },
                                                  },
                                                  [
                                                    _c("v-subheader", [
                                                      _vm._v(
                                                        "Display Recurring Identical Trips to admins only?"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "v-radio-group",
                                                      {
                                                        staticClass: "ml-4",
                                                        attrs: { row: "" },
                                                        model: {
                                                          value:
                                                            _vm.display
                                                              .showRecurringTripsOnlyToAdmin,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.display,
                                                              "showRecurringTripsOnlyToAdmin",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "display.showRecurringTripsOnlyToAdmin",
                                                        },
                                                      },
                                                      [
                                                        _c("v-radio", {
                                                          attrs: {
                                                            label: "Yes",
                                                            value: true,
                                                          },
                                                        }),
                                                        _c("v-radio", {
                                                          attrs: {
                                                            label: "No",
                                                            value: false,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        ' Always display "Out of County" question, regardless of whether trip is Overnight/Out-of-State? '
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.outOfCounty,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "outOfCounty",
                                              $$v
                                            )
                                          },
                                          expression: "display.outOfCounty",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display option to identify trip as "Out of County" or "Out of District" on these trip types:'
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox",
                                          attrs: {
                                            value: type.id,
                                            label: type.name,
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.display.outOfCountyTypes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "outOfCountyTypes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.outOfCountyTypes",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Require submitter answers Overnight/Out-of-State question?"
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value:
                                            _vm.display.overnightOOSRequired,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "overnightOOSRequired",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.overnightOOSRequired",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Actual Time TBD" message under trip date/time? '
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.actualTimeTBD,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "actualTimeTBD",
                                              $$v
                                            )
                                          },
                                          expression: "display.actualTimeTBD",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase font-weight-medium grey lighten-3",
                          },
                          [_vm._v(" Destination ")]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "v-row",
                              { staticClass: "my-2", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Display Stops on the Way and Return after Destination? "
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.additionalStops,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "additionalStops",
                                              $$v
                                            )
                                          },
                                          expression: "display.additionalStops",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Allow users to request destinations not listed in Common Destinations table?"
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.newDestination,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "newDestination",
                                              $$v
                                            )
                                          },
                                          expression: "display.newDestination",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase font-weight-medium grey lighten-3",
                          },
                          [_vm._v(" Attendees ")]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "v-row",
                              { staticClass: "my-2", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(' Display "Grade Level" option? '),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            value: type.id,
                                            label: type.name,
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.display.gradeLevel,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "gradeLevel",
                                                $$v
                                              )
                                            },
                                            expression: "display.gradeLevel",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        ' Display "Description of group" section? '
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.groupDescription,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "groupDescription",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.groupDescription",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Display emergency contact and phone number for these trip types: "
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            value: type.id,
                                            label: type.name,
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.display.emergencyContact,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "emergencyContact",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.emergencyContact",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Display health concerns question on these trip types: "
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            value: type.id,
                                            label: type.name,
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.display.healthConcerns,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "healthConcerns",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.healthConcerns",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Students away during lunch" question on these trip types:'
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            value: type.id,
                                            label: type.name,
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.display.awayForLunch,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "awayForLunch",
                                                $$v
                                              )
                                            },
                                            expression: "display.awayForLunch",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Packed Lunch" question? '
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.packedLunch,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "packedLunch",
                                              $$v
                                            )
                                          },
                                          expression: "display.packedLunch",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Break out adults and students by gender?"
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value:
                                            _vm.display.splitAttendeesByGender,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "splitAttendeesByGender",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.splitAttendeesByGender",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.display.splitAttendeesByGender
                                      ? [
                                          _c("v-subheader", [
                                            _vm._v(
                                              "Include an option for non-binary?"
                                            ),
                                          ]),
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "ml-4",
                                              attrs: { row: "" },
                                              model: {
                                                value: _vm.display.nonBinary,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.display,
                                                    "nonBinary",
                                                    $$v
                                                  )
                                                },
                                                expression: "display.nonBinary",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Number of Special Needs Students" field?'
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value:
                                            _vm.display.numSpecialNeedsStudents,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "numSpecialNeedsStudents",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.numSpecialNeedsStudents",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase font-weight-medium grey lighten-3",
                          },
                          [_vm._v(" Transportation ")]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "v-row",
                              { staticClass: "my-2", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Do you need a driver assigned/provided" question?'
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.driverAssigned,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "driverAssigned",
                                              $$v
                                            )
                                          },
                                          expression: "display.driverAssigned",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.display.driverAssigned
                                      ? [
                                          _c("v-subheader", [
                                            _vm._v(
                                              'Require ""Do you need a driver assigned/provided" question?" to be answered?'
                                            ),
                                          ]),
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "ml-4",
                                              attrs: { row: "" },
                                              model: {
                                                value:
                                                  _vm.display.requireDriverInfo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.display,
                                                    "requireDriverInfo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "display.requireDriverInfo",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "External Transportation" question?'
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.extTransportation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "extTransportation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.extTransportation",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Display option to indicate Drop-off Only?"
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.dropoffOnly,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "dropoffOnly",
                                              $$v
                                            )
                                          },
                                          expression: "display.dropoffOnly",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Display option to indicate Pickup Only?"
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.pickupOnly,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "pickupOnly",
                                              $$v
                                            )
                                          },
                                          expression: "display.pickupOnly",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v('Require "Comments" field?'),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.comments,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "comments",
                                              $$v
                                            )
                                          },
                                          expression: "display.comments",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Allow split trips with two legs - Leave/Arrive and Depart/Return times for some trips?"
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.splitTrips,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "splitTrips",
                                              $$v
                                            )
                                          },
                                          expression: "display.splitTrips",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Need Lift/Special Needs Vehicle" question?'
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value:
                                            _vm.display.specialNeedsVehicle,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "specialNeedsVehicle",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.specialNeedsVehicle",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "How Many Vehicles Need a Lift/Special Needs Vehicle" field?'
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value:
                                            _vm.display.numSpecialNeedsVehicle,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "numSpecialNeedsVehicle",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.numSpecialNeedsVehicle",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Comments Concerning Vehicle Needs" section? '
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value:
                                            _vm.display
                                              .commentsConcerningVehicle,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "commentsConcerningVehicle",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.commentsConcerningVehicle",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Display Arrive/Depart Venue Date/Times on these trip types:"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            value: type.id,
                                            label: type.name,
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.display.venueDateTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "venueDateTime",
                                                $$v
                                              )
                                            },
                                            expression: "display.venueDateTime",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Send Quotes" button to end user when vehicle type of Contractor/Approved Charter is selected? '
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.sendQuotes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "sendQuotes",
                                              $$v
                                            )
                                          },
                                          expression: "display.sendQuotes",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Display list of contractors to the trip requester? "
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.contractors,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "contractors",
                                              $$v
                                            )
                                          },
                                          expression: "display.contractors",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.display.contractors
                                      ? _c("v-subheader", [
                                          _vm._v(
                                            " Require selection of Contractor Name/Approved Charter Company before the requester can submit a trip? "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.display.contractors
                                      ? _c(
                                          "v-radio-group",
                                          {
                                            staticClass: "ml-4",
                                            attrs: { row: "" },
                                            model: {
                                              value:
                                                _vm.display.requireContractors,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.display,
                                                  "requireContractors",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "display.requireContractors",
                                            },
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Yes",
                                                value: true,
                                              },
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "No",
                                                value: false,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        " Allow edit of Vehicle Pickup and Return Date/Times by non-admin and non-owner users? "
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value:
                                            _vm.display
                                              .nonAdminEditVehicleDateTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "nonAdminEditVehicleDateTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.nonAdminEditVehicleDateTime",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase font-weight-medium grey lighten-3",
                          },
                          [_vm._v(" Funding ")]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "v-row",
                              { staticClass: "my-2", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Third Party Payment" information?'
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.thirdPartyPayment,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "thirdPartyPayment",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.thirdPartyPayment",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.display.thirdPartyPayment,
                                        expression: "display.thirdPartyPayment",
                                      },
                                    ],
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Third Party Payment" information on which trip types?'
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            label: type.name,
                                            value: type.id,
                                            multiple: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleFundingSourceRequired(
                                                type.id,
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.display
                                                .thirdPartyPaymentTripTypes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "thirdPartyPaymentTripTypes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.thirdPartyPaymentTripTypes",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _vm.display.thirdPartyPayment
                                      ? _c("v-subheader", [
                                          _vm._v(
                                            ' Would you like to include "P-Card" in your pay options? '
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.display.thirdPartyPayment
                                      ? _c(
                                          "v-radio-group",
                                          {
                                            staticClass: "ml-4",
                                            attrs: { row: "" },
                                            model: {
                                              value: _vm.display.pcardOption,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.display,
                                                  "pcardOption",
                                                  $$v
                                                )
                                              },
                                              expression: "display.pcardOption",
                                            },
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Yes",
                                                value: true,
                                              },
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "No",
                                                value: false,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "10" } },
                                          [
                                            _vm.display.pcardOption
                                              ? _c("user-select", {
                                                  attrs: {
                                                    users: _vm.users,
                                                    label:
                                                      "P-Card Email Notification User",
                                                    noChips: "",
                                                    saveEmail: "",
                                                  },
                                                  on: {
                                                    handleUserSelect: function (
                                                      $event
                                                    ) {
                                                      _vm.display.notifyPCard =
                                                        $event
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.display.notifyPCard,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.display,
                                                        "notifyPCard",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "display.notifyPCard",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Show Funding selections for Requesters?"
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        on: {
                                          change: function ($event) {
                                            _vm.display.showFunding
                                              ? null
                                              : (_vm.display.requesterFundingSourceRequired =
                                                  [])
                                          },
                                        },
                                        model: {
                                          value: _vm.display.showFunding,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "showFunding",
                                              $$v
                                            )
                                          },
                                          expression: "display.showFunding",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "9" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Funding Source is required by Requesters for these trip types:"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            disabled: !_vm.display.showFunding,
                                            label: type.name,
                                            value: type.id,
                                            multiple: "",
                                          },
                                          on: {
                                            change: () =>
                                              _vm.handleRequesterFundingSourceRequiredChange(
                                                type.id
                                              ),
                                          },
                                          model: {
                                            value:
                                              _vm.display
                                                .requesterFundingSourceRequired,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "requesterFundingSourceRequired",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.requesterFundingSourceRequired",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Funding Source is required by Approvers for these trip types:"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            disabled:
                                              _vm.approverFundingSourceRequiredDisabled(
                                                type.id
                                              ),
                                            label: type.name,
                                            value: type.id,
                                            multiple: "",
                                          },
                                          model: {
                                            value:
                                              _vm.display
                                                .approverFundingSourceRequired,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "approverFundingSourceRequired",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.approverFundingSourceRequired",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Maximum number of funding source allowed:"
                                      ),
                                    ]),
                                    _c("v-text-field", {
                                      attrs: {
                                        row: "",
                                        outlined: "",
                                        "hide-details": "",
                                        label: "Funding Source Limit",
                                        type: "number",
                                        min: "1",
                                      },
                                      model: {
                                        value: _vm.display.fundingSourceLimit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.display,
                                            "fundingSourceLimit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "display.fundingSourceLimit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        " Require Budget Codes on Funding Sources to be listed in Budget Code Table? "
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.requireBCFromTable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "requireBCFromTable",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.requireBCFromTable",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        " Allow admins to select any Funding Source on trip regardless of the Funding Source being Location specific? "
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.showAllFS,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "showAllFS",
                                              $$v
                                            )
                                          },
                                          expression: "display.showAllFS",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Budget Code is required for Approvers for these trip types:"
                                      ),
                                    ]),
                                    _vm._l(_vm.tripTypes, function (type, i) {
                                      return _c(
                                        "v-row",
                                        {
                                          key: i,
                                          staticClass: "px-4",
                                          attrs: { dense: "" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: { label: type.name },
                                                model: {
                                                  value:
                                                    _vm.display
                                                      .budgetCodeRequired[
                                                      type.id
                                                    ].required,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.display
                                                        .budgetCodeRequired[
                                                        type.id
                                                      ],
                                                      "required",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "display.budgetCodeRequired[type.id].required",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  label:
                                                    "Level Budget Code Required",
                                                  items: _vm.approvalLevels,
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.display
                                                      .budgetCodeRequired[
                                                      type.id
                                                    ].level,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.display
                                                        .budgetCodeRequired[
                                                        type.id
                                                      ],
                                                      "level",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "display.budgetCodeRequired[type.id].level",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                  ],
                                  2
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Show message when allotment is depleted on Funding Source?"
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.allotmentDepleted,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "allotmentDepleted",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "display.allotmentDepleted",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase font-weight-medium grey lighten-3",
                          },
                          [_vm._v(" Additional Information ")]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "v-row",
                              { staticClass: "my-2", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        ' Display "Educational Objective of Trip" section? '
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            value: type.id,
                                            label: type.name,
                                            multiple: "",
                                          },
                                          model: {
                                            value:
                                              _vm.display.educationalObjective,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "educationalObjective",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.educationalObjective",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Do you need a substitute" question?'
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.substitute,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "substitute",
                                              $$v
                                            )
                                          },
                                          expression: "display.substitute",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.display.substitute
                                      ? _c("v-subheader", [
                                          _vm._v(
                                            ' Require "Do you need a substitute" question to be answered? '
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.display.substitute
                                      ? _c(
                                          "v-radio-group",
                                          {
                                            staticClass: "ml-4",
                                            attrs: { row: "" },
                                            model: {
                                              value:
                                                _vm.display.substituteRequired,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.display,
                                                  "substituteRequired",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "display.substituteRequired",
                                            },
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Yes",
                                                value: true,
                                              },
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "No",
                                                value: false,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        "Display permission slip section on these trip types:"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      _vm._l(_vm.tripTypes, function (type, i) {
                                        return _c("v-checkbox", {
                                          key: i,
                                          staticClass: "checkbox ml-4",
                                          attrs: {
                                            value: type.id,
                                            label: type.name,
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.display.permissionSlip,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "permissionSlip",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.permissionSlip",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Special Indicators" on these trip types:'
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-wrapper" },
                                      [
                                        _vm._l(
                                          _vm.tripTypes,
                                          function (type, i) {
                                            return _c("v-checkbox", {
                                              key: i,
                                              staticClass: "checkbox ml-4",
                                              attrs: {
                                                value: type.id,
                                                label: type.name,
                                                multiple: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.display.specialIndicators,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.display,
                                                    "specialIndicators",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "display.specialIndicators",
                                              },
                                            })
                                          }
                                        ),
                                        _vm.display.specialIndicators.length > 0
                                          ? _c(
                                              "v-subheader",
                                              { staticClass: "full-width" },
                                              [
                                                _vm._v(
                                                  " Require at least one special indicator to be selected? "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.display.specialIndicators.length > 0
                                          ? _c(
                                              "v-radio-group",
                                              {
                                                staticClass: "ml-4",
                                                attrs: { row: "" },
                                                model: {
                                                  value:
                                                    _vm.display
                                                      .specialIndicatorsRequired,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.display,
                                                      "specialIndicatorsRequired",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "display.specialIndicatorsRequired",
                                                },
                                              },
                                              [
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "Yes",
                                                    value: true,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "No",
                                                    value: false,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.display.specialIndicators.length > 0
                                          ? _c(
                                              "v-subheader",
                                              { staticClass: "full-width" },
                                              [
                                                _vm._v(
                                                  " Display special indicators to administrators only? "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.display.specialIndicators.length > 0
                                          ? _c(
                                              "v-radio-group",
                                              {
                                                staticClass: "ml-4",
                                                attrs: { row: "" },
                                                model: {
                                                  value:
                                                    _vm.display
                                                      .specialIndicatorsAdmin,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.display,
                                                      "specialIndicatorsAdmin",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "display.specialIndicatorsAdmin",
                                                },
                                              },
                                              [
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "Yes",
                                                    value: true,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "No",
                                                    value: false,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase font-weight-medium grey lighten-3",
                          },
                          [_vm._v(" Miscellaneous ")]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "v-row",
                              { staticClass: "my-2", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        'Display "Create Duplicate Request" button to end user?'
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.duplicate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "duplicate",
                                              $$v
                                            )
                                          },
                                          expression: "display.duplicate",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("v-subheader", [
                                      _vm._v(
                                        " Limit time before trip when it can be canceled or rescheduled by the requester? "
                                      ),
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.display.limitReschedule,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.display,
                                              "limitReschedule",
                                              $$v
                                            )
                                          },
                                          expression: "display.limitReschedule",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true },
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.display.limitReschedule
                                      ? _c("v-text-field", {
                                          attrs: {
                                            outlined: "",
                                            label: "Days Notice Required",
                                            type: "number",
                                            min: "0",
                                          },
                                          model: {
                                            value:
                                              _vm.display.limitRescheduleDays,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "limitRescheduleDays",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.limitRescheduleDays",
                                          },
                                        })
                                      : _vm._e(),
                                    _c("v-spacer"),
                                    _c("v-textarea", {
                                      attrs: {
                                        outlined: "",
                                        rows: "1",
                                        "auto-grow": "",
                                        label:
                                          "Cancelation / Reschedule Limit Message",
                                      },
                                      model: {
                                        value:
                                          _vm.display.limitRescheduleMessage,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.display,
                                            "limitRescheduleMessage",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "display.limitRescheduleMessage",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }